<template>
  <div class="layouts-notification-container">
    <slot />
    <EssentialsCloseButton
      v-if="
        mainStore.platform === 'desktop' || mainStore.platform === 'mobileweb'
      "
      :close-popup="true"
    />
  </div>
</template>

<script>
import { mapStores } from 'pinia';
export default {
  head() {
    return {
      bodyAttrs: { class: 'notification-iframe medium' },
      title: this.mainStore.meta_title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.mainStore.meta_description,
        },
      ],
    };
  },

  computed: {
    ...mapStores(useMainStore, useMeStore),
  },

  created() {
    this.meStore.display_type = 'notification';
    this.meStore.set_collector_token(this.$route.query.collector_token);

    if (this.$route.query.platform) {
      this.mainStore.platform = this.$route.query.platform;
    }

    this.meStore.fetchCollector();
  },

  mounted() {
    this.$bugsnag.user = {
      collector_token: this.$route.query.collector_token,
      platform: this.$route.query.platform,
    };
  },
};
</script>

<style lang="scss" scoped>
.layouts-notification-container {
  @apply bg-white h-screen w-screen font-theme-text;
  user-select: none;
  overflow: hidden
}
</style>
